import { Component } from '@angular/core';
import { FeatureConfigFacade } from '@common/data-access-feature-config';
import { fadeInOut } from '@common/ui-sales-components';
import { BuildConfigService, LoaderService } from '@common/util-foundation';
import { BuildConfig } from '@common/util-models';
import { NavigationLinks } from '@domgen/dgx-fe-business-models';
import { map } from 'rxjs/operators';

@Component({
  selector: 'reinstate-app-root',
  templateUrl: './reinstate-app.component.html',
  styleUrls: ['./reinstate-app.component.scss'],
  animations: [fadeInOut],
})
export class ReinstateAppComponent {
  config: BuildConfig = this.buildConfigService.config;
  headerNavigationLinks: NavigationLinks = this.config.header;
  loading$ = this.loaderService.loading$;
  clientLogoPath$ = this.featureConfigFacade
    .sidedoor$()
    .pipe(map((config) => config?.clientLogoPath));

  constructor(
    private readonly buildConfigService: BuildConfigService,
    private readonly loaderService: LoaderService,
    private featureConfigFacade: FeatureConfigFacade
  ) {}
}
