import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NavigationLinks } from '@domgen/dgx-fe-business-models';

const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('0ms 200ms', style({ opacity: 1 })),
  ]),
  transition(':leave', [animate('0ms 200ms', style({ opacity: 0 }))]),
]);
@Component({
  selector: 'dgx-ui-wl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOut],
})
export class HeaderComponent {
  @Input() navigationLinks!: NavigationLinks;
  @Input() showNavigationLinks = true;
  /**
   * Receives a link to a clients logo, if required and it will render the powered by logo
   */
  @Input() clientLogoPath!: string;

  /**
   * Emits a click event back to the parent component
   */
  @Output() clicked: EventEmitter<{
    event: MouseEvent;
    link: string;
  }> = new EventEmitter();

  /**
   * Emits a toggled event back to the parent component
   */
  @Output() toggled: EventEmitter<string> = new EventEmitter();
  /**
   * Tracks menu open state
   */
  isOpen = false;

  /**
   * Toggle mobile menu
   */
  onToggleMenu() {
    this.isOpen = !this.isOpen;
    this.toggled.emit(this.isOpen ? 'open' : 'closed');
  }
  /**
   * Listens and fires emit `clicked` when clicked
   */
  onClick(event: MouseEvent, link: string | undefined) {
    this.clicked.emit({
      event,
      link:
        link ||
        (event.currentTarget as HTMLAnchorElement).textContent ||
        'Unknown',
    });
    this.isOpen = false;
  }
}
