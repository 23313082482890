import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NavigationLinks } from '@domgen/dgx-fe-business-models';

const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('0ms 200ms', style({ opacity: 1 })),
  ]),
  transition(':leave', [animate('0ms 200ms', style({ opacity: 0 }))]),
]);

@Component({
  selector: 'dgx-ui-wl-my-account-header',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOut],
})
export class MyAccountComponent {
  @Input() navigationLinks!: NavigationLinks;
  /**
   * Receives a link to a clients logo, if required and it will render the powered by logo
   */
  @Input() clientLogoPath: string | undefined;
  @Input() isLoggedIn = false;

  @Output() logout = new EventEmitter();
  /**
   * Emits a click event back to the parent component
   */
  @Output() clicked: EventEmitter<unknown> = new EventEmitter();

  /**
   * Emits a toggled event back to the parent component
   */
  @Output() toggled: EventEmitter<string> = new EventEmitter();
  /**
   * Tracks menu open state
   */
  isOpen = false;
  navOpen = false;

  /**
   * Toggle mobile menu
   */
  onToggleMenu() {
    this.isOpen = !this.isOpen;
    this.toggled.emit(this.isOpen ? 'open' : 'closed');
  }
  /**
   * Toggle mobile menu
   */
  onToggleSubMenu() {
    this.navOpen = !this.navOpen;
    this.toggled.emit(this.navOpen ? 'open' : 'closed');
  }
  /**
   * Listens and fires emit `clicked` when clicked
   */
  onClick(e: Event) {
    this.clicked.emit(e);
    this.isOpen = false;
  }

  logoutClick() {
    this.logout.emit(null);
  }
}
