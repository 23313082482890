<div errorPageGaTagging class="error container mt-4">
  <h3 class="error-heading mb-1">Sorry, something’s not right</h3>
  <p class="mb-2">
    It looks like there’s been a technical issue but we’re working to fix it
  </p>
  <strong> Try calling our friendly team to receive your quote on: </strong>
  <p class="help-number mb-3">
    <a class="link" href="tel:08005978600">0800 5978600</a>
  </p>
  <strong> We’re available: </strong>
  <p>8am to 8pm Monday – Friday</p>
  <p>9am to 5pm Saturday – Sunday</p>
</div>
