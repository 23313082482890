import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Error404Component } from '@common/ui-whitelabel-components';

const routes: Routes = [
  //todo: what should the default page actually be?
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/reinstate',
  },
  {
    path: 'reinstate',
    loadChildren: () =>
      import('@reinstate/feature-reinstate').then(
        (m) => m.ReinstateFeatureReinstateModule
      ),
  },
  {
    path: 'confirmation',
    loadChildren: () =>
      import('@reinstate/feature-confirmation').then(
        (m) => m.ReinstateFeatureConfirmationModule
      ),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('@reinstate/feature-error-handling').then(
        (m) => m.ReinstateFeatureErrorHandlingModule
      ),
  },
  {
    path: '**',
    component: Error404Component,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
      relativeLinkResolution: 'corrected',
    }),
  ],
  exports: [RouterModule],
})
export class ReinstateFeatureShellRoutingModule {} // Main routes for application
