import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { GoogleTagConfig } from '@common/util-models';
import { ReinstateFeatureShellModule } from '@reinstate/feature-shell';
import { environment } from '@reinstate/util-environment';
import { AppComponent } from './app.component';
import { COMPONENT_MAPPING } from './cms-config/cms-component-mapping.config';
import { cmsDataUrl } from './cms-config/cms-data-url';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    ReinstateFeatureShellModule.forRoot({
      componentMapping: COMPONENT_MAPPING,
      gaTagsConfig: ({} as unknown) as GoogleTagConfig,
      cmsDataUrl,
    }),
  ],
  providers: [
    { provide: 'isDevelopmentEnvironment', useValue: !environment.production }, // to enable debug logger
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
